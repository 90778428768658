<template>
  <q-page :style-fn="getAreaDimension">
    <div
      class="column view-render-class"
      :style="`${areaHeight ? `height: ${areaHeight}px` : ''}`"
    >
      <div class="col-auto">
        <div class="row">
          <div class="col">
            <LayoutBreadcrumb
              :show-back-button="true"
              :metadata="agency"
            >
              <div
                class="row q-gutter-md items-center"
                v-if="isAdmin && editLink"
              >
                <q-btn
                  unelevated
                  no-caps
                  color="warning"
                  class="col-auto btn-view"
                  :to="editLink"
                  padding="3px 12px"
                >
                  <b>Alterar</b>
                </q-btn>

                <q-btn
                  unelevated
                  no-caps
                  color="primary"
                  class="col-auto btn-view"
                  :to="addLink"
                  padding="3px 12px"
                >
                  Cadastrar
                </q-btn>
              </div>
            </LayoutBreadcrumb>
          </div>
        </div>
      </div>

      <div class="col max-container-width q-mx-auto">
        <AgencyRender
          v-if="agency"
          :doc="agency"
        />
      </div>
    </div>

    <q-inner-loading
      :showing="isLoading"
      style="z-index: 9999"
    >
      <q-spinner-ios
        size="70px"
        color="primary"
      />
    </q-inner-loading>
  </q-page>
</template>

<script>
import api from '@/aws/api'
import { mapGetters } from 'vuex'
import AgencyRender from '../components/AgencyRender'
import LayoutBreadcrumb from '@/components/layouts/LayoutBreadcrumb'

import {
  ROUTE_AGENCY_EDIT,
  ROUTE_AGENCY_CREATE
} from '../constants'

export default {
  components: {
    AgencyRender,
    LayoutBreadcrumb
  },

  data () {
    return {
      agency: null,
      editLink: null,
      isLoading: true,
      areaHeight: null,
      addLink: { name: ROUTE_AGENCY_CREATE }
    }
  },

  mounted () {
    const vm = this
    const isGeneralRole = vm.$store.getters.isGeneralRole
    if (!isGeneralRole) return vm.$router.go(-1)

    api.getAgency(vm.$route.params.id).then((agency) => {
      vm.agency = agency
      vm.isLoading = false
      vm.editLink = { name: ROUTE_AGENCY_EDIT, params: { id: agency.id } }
    })
  },

  computed: {
    ...mapGetters({
      isAdmin: 'isAdmin'
    })
  },

  methods: {
    getAreaDimension (offset, height) {
      this.areaHeight = height - offset
    }
  }
}
</script>

<style lang="sass">
  .view-render-class
    .btn-view
      font-style: normal
      font-weight: 600
      font-size: 0.8em
</style>
