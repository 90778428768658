<template>
  <div class="row no-wrap full-height q-pt-sm">
    <q-scroll-area
      :thumb-style="leftThumbScroll"
      :bar-style="leftBarScroll"
      style="height: 100%"
      class="col"
    >
      <div class="row">
        <div class="col-12 bg-white border-radius-4 border-box q-pa-sm">
          <div class="column">
            <q-item-label
              caption
              class="col-auto q-pb-xs"
            >
              Orgão Emissor
            </q-item-label>

            <div class="col q-px-sm">
              {{ doc.name }}
            </div>
          </div>
          <div class="column">
            <q-item-label
              caption
              class="col-auto q-pb-xs"
            >
              Tipo
            </q-item-label>

            <div class="col q-px-sm">
              {{ doc.type }}
            </div>
          </div>
        </div>
      </div>
    </q-scroll-area>
  </div>
</template>

<script>

import {

  leftBarScroll,
  rightBarScroll,
  leftThumbScroll,
  rightThumbScroll

} from '@/components/documents'

export default {

  props: {
    doc: {}
  },

  data () {
    return {
      leftBarScroll,
      rightBarScroll,
      leftThumbScroll,
      rightThumbScroll
    }
  }

}

</script>

<style lang="sass" scoped>
  .title
    font-size: 1.6em
    font-weight: bold

  .datetime
    font-size: 0.8em

  .observation
    font-size: 1.2em
</style>
