import { render, staticRenderFns } from "./AgencyView.vue?vue&type=template&id=78980914&"
import script from "./AgencyView.vue?vue&type=script&lang=js&"
export * from "./AgencyView.vue?vue&type=script&lang=js&"
import style0 from "./AgencyView.vue?vue&type=style&index=0&id=78980914&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerIos from 'quasar/src/components/spinner/QSpinnerIos.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtn,QInnerLoading,QSpinnerIos});
